@import "../../../assets/styles/variables.scss";

.cookie {
  max-width: 320px;
  width: 95%;
  padding: 24px;
  background: rgba(29, 30, 33, 0.82);
  border: 1px solid rgba(255, 255, 255, 0.16);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
  backdrop-filter: blur(3px);
  border-radius: 16px;
  position: fixed;
  z-index: 99999999999999;
  right: 24px;
  bottom: 24px;
  transform: translateY(24px);
  transition: all 300ms ease-in-out;
  transition-delay: 1000ms;
  visibility: hidden;
  opacity: 0;

  &.open {
    visibility: visible;
    transform: translateY(0);
    opacity: 1;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 18px;
    color: $white;
    margin-bottom: 8px;
  }

  a {
    color: $white;
  }

  .btn {
    margin-right: 12px;
    display: inline-block;
  }
}

@media screen and (max-width: $mobile) {
  .cookie {
    max-width: 90%;
    right: 5%;

    p {
      text-align: center;
    }

    .cookie-mobile-btns {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .btn {
        width: 48%;
        margin: 0;
      }
    }
  }
}
