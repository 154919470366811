@import "../../../assets/styles/variables.scss";

.preferences_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  margin-bottom: 24px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 450px;
  background-color: #ffffff;
  z-index: 10;

  .logo {
    height: 19px;
    display: block;
  }

  .menu_open {
    display: block;
    width: 30px;
    height: 30px;
    padding: 4px;
    background-color: transparent;

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}

.inner_container {
  padding: 0px 20px;

  .top_right_button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 30px;

    svg {
      width: 10px;
      height: 10px;

      path {
        stroke: #ffffff;
      }
    }

    &.left_right {
      justify-content: space-between;
      padding-top: 34px;

      .nav_go_back {
        padding: 10px;
        padding-left: 0;
        margin-bottom: 0;

        svg {
          width: auto;
          height: auto;
        }
      }
    }
  }

  .search_preferences_input {
    .input.no-animation {
      border-radius: 60px;

      .label.icon {
        svg {
          circle,
          path {
            stroke: #ffffff;
          }
        }
      }
    }
  }

  .preferences_switcher {
    margin: 20px 0;
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;

    button {
      padding: 10px 16px;
      border-radius: 24px;
      color: #ffffff;
      font-family: "manrope", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 122%;
      border: 1.5px solid rgba(152, 152, 152, 0.33);
      color: #000000;
      background-color: transparent;
      color: #ffffff;
      display: inline-block;
      margin-right: 10px;

      &.active {
        background: $blue;
        border-color: $blue;
      }

      span {
        color: rgba(255, 255, 255, 0.3);
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 122%;
      }
    }
  }

  &.popup {
    padding: 0;
  }

  .preferences_cover {
    display: block;
    width: 100%;
    margin: 20px 0;
    height: 200px;
    border-radius: 24px;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .preferences_display {
    margin-top: 20px;
    background-color: #191919;
    border-radius: 12px;
    padding: 16px;

    .preferences_options_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 6px 0;
      margin-bottom: 6px;

      .text {
        margin-bottom: 0;
      }

      .add_new_preference {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;
        justify-content: center;
      }
    }

    .preferences {
      padding-bottom: 0px;

      .preferences_row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 6px;
        gap: 10px;
      }

      &::-webkit-scrollbar {
        height: 5px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: $darkgrey;
        outline: none;
      }

      .single_preference {
        border-radius: 60px;
        background: rgba(255, 255, 255, 0.07);
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 122%;
        color: rgba(255, 255, 255, 0.6);
        padding: 8px;
        min-width: fit-content;

        &.selected {
          background-color: #ffffff;
          color: #000000;
        }
      }

      .see_all {
        margin-top: 16px;
        margin-bottom: 0;
        margin-left: auto;
        margin-right: auto;
        width: fit-content;
      }
    }
  }

  .load_more_preferences {
    position: relative;
    margin-top: 20px;
  }

  .save_div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 12px;

    .btn {
      position: relative;
      margin: 0;
      bottom: unset;
      left: unset;
      width: auto;
    }
  }

  .delete_custom_preference {
    color: rgba(242, 0, 44, 1);
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    background-color: transparent;
  }

  .addNewLevelOneCategory {
    position: fixed;
    right: calc(100% - 430px);
    bottom: 130px;
    background-color: transparent;
  }

  &.popup {
    .btn {
      box-shadow: none;
    }

    .moveToPreviousCategory {
      background-color: transparent;
      display: flex;
      font-size: 14px;
      margin-bottom: 12px;
      cursor: pointer;
      align-items: center;
      justify-content: flex-start;
      gap: 4px;
      color: #ffffff;

      svg {
        height: 12px;

        path {
          stroke: #ffffff;
          stroke-opacity: 1;
        }
      }
    }
  }

  &.padding_bottom {
    padding-bottom: 150px;
  }

  & > .left {
    max-width: 800px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  & > .right {
    .modal_popup {
      position: fixed;
      top: 50px;
      right: 10px;
      height: calc(100% - 100px);
      width: 400px;
      align-items: flex-start;
      padding-top: 90px;
      background-color: transparent;
      backdrop-filter: none;
      border-radius: 24px;
      background: #0d0d0d;
      box-shadow: 0px -4px 39px -14px rgba(35, 66, 123, 0.46);

      &.open {
        transform: translateX(0);
        .popup_container {
          background-color: transparent !important;
          padding: 0;
          max-height: 100%;
          border-radius: 0;

          .share_business_popup_padding {
            padding: 20px 0;
          }
        }
      }
      &.close {
        transform: translateX(50px);
      }
    }
  }

  &.two_column {
    // display: flex;
    // flex-direction: row;
    // gap: 30px;

    .left {
      padding-right: 400px;
      max-width: 100%;
      padding-left: 200px;
    }
  }

  .hide_1300 {
    display: none;
  }
  .show_1300 {
    display: block;
  }
}

.nav_btn {
  .btn {
    margin: 12px 0 24px 0;
    width: 100%;
    position: static;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
}

.beta_back_button {
  width: 25px;
  height: 25px;

  svg {
    width: 100%;
    height: 100%;
  }
}

.beta_container {
  padding: 30px;

  .bg_container {
    position: fixed;
    left: 0;
    top: 0;
    width: 300px;
    height: 100vh;
    z-index: 2;
    padding: 30px;
  }
}

.continue_btn_bg {
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 450px;
  background: white;
  height: 150px;
  z-index: 2;
}

.btn.continue {
  display: block;
  width: calc(422px);
  margin: 16px 0;
  // box-shadow: 0px 15px 23px rgba(30, 52, 95, 0.5);
  // margin-bottom: 50px;
  position: fixed;
  bottom: 60px;
  left: 12px;
  z-index: 3;
}

@media only screen and (max-width: 1300px) {
  .inner_container {
    .left {
      padding-right: 0px;
    }

    &.two_column {
      display: flex;
      flex-direction: row;
      gap: 30px;

      .left {
        padding-right: 0px;
        padding-left: 0px;
      }

      .right {
        .modal_popup {
          position: fixed;
          top: 0;
          right: 0;
          height: 100vh;
          width: 100%;
          align-items: center;
          padding-top: 0px;
          background-color: rgba(255, 255, 255, 0.05);
          backdrop-filter: blur(6px);

          &.open .popup_container {
            background-color: #000000 !important;
            padding: 20px;
            max-height: 70%;
            border-radius: 24px;
          }
        }
      }
    }
  }

  .beta_container {
    .beta_app {
      padding-left: 300px;
    }
  }
}

@media screen and (max-width: $mobile) {
  .inner_container {
    padding-top: 30px;
    padding-bottom: 150px;

    .top_right_button {
      position: absolute;
      right: 20px;
      top: 20px;
      z-index: 2;
    }

    .preferences_display {
      .preferences {
        &::-webkit-scrollbar {
          display: none;
        }

        .single_preference {
          font-size: 12px;
        }
      }
    }

    .addNewLevelOneCategory {
      right: 20px;
    }

    &.two_column {
      display: block;
    }

    .hide_1300 {
      display: block;
    }
    .show_1300 {
      display: none;
    }
  }

  .beta_container {
    padding: 0;

    .bg_container {
      position: static;
      width: 0;
      height: 0;
    }

    .beta_app {
      padding-left: 0;
    }
  }
}

@media screen and (max-width: 450px) {
  .btn.continue {
    width: calc(100% - 24px);
  }
}
