@import "../../../assets/styles/variables.scss";

.confirmation_modal_popup {
  display: block;
  width: 100%;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.1);
  // backdrop-filter: blur(2px);
  z-index: 2;
  transition: all 250ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;

  .popup_container {
    max-width: 300px;
    width: 90%;
    background-color: #ffffff;
    box-shadow: 25px 49px 82px rgba(63, 93, 255, 0.05);
    backdrop-filter: blur(12px);
    border-radius: 12px;
    padding: 18px;
    transition: all 250ms ease-in-out;

    .btn {
      display: block;
      width: 100%;
      margin-top: 6px;
    }
  }

  &.desktopLeftSide {
    .popup_container {
      position: absolute;
      top: 50%;
      left: 220px;
      transform: translate(-50%, -50%);
    }
  }

  &.close {
    visibility: hidden;
    opacity: 0;
  }

  &.open {
    visibility: visible;
    opacity: 1;
  }

  &.dark {
    .popup_container {
      background-color: #0d0d0d;
    }

    .sub_heading {
      color: #ffffff !important;
    }

    .text {
      color: $lightgrey;
    }

    .btn {
      &.dark_red {
        background-color: rgba(242, 0, 44, 1);
      }

      &.grey_light {
        background-color: rgba(29, 34, 41, 1);
        color: rgba(124, 125, 130, 1);
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .confirmation_modal_popup {
    .popup_container {
      width: 90%;
    }

    &.desktopLeftSide {
      .popup_container {
        position: relative;
        top: unset;
        left: unset;
        transform: none;
      }
    }
  }
}
