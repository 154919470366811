.slide_in_padding {
  padding-bottom: 150px;
}

.slide_in_bottom {
  margin-top: 30px;
  background: #0d0d0d;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 16px;
  padding-bottom: 60px;
  z-index: 2;

  .btn {
    display: block;
    width: 100%;
  }
}
