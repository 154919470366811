// Basic
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(3px);
  z-index: 9999;
  transition: all 300ms ease-in-out;

  .loader_icon {
    display: block;
    width: 200px;
    height: 200px;
    transform: scale(0.5);
    --loader-color: #b9bbc0;

    @keyframes ldio-0at4w42a1mmu {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
    .ldio-0at4w42a1mmu div {
      left: 94px;
      top: 48px;
      position: absolute;
      animation: ldio-0at4w42a1mmu linear 1s infinite;
      background: var(--loader-color);
      width: 12px;
      height: 24px;
      border-radius: 6px / 12px;
      transform-origin: 6px 52px;
    }
    .ldio-0at4w42a1mmu div:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -0.9166666666666666s;
      background: var(--loader-color);
    }
    .ldio-0at4w42a1mmu div:nth-child(2) {
      transform: rotate(30deg);
      animation-delay: -0.8333333333333334s;
      background: var(--loader-color);
    }
    .ldio-0at4w42a1mmu div:nth-child(3) {
      transform: rotate(60deg);
      animation-delay: -0.75s;
      background: var(--loader-color);
    }
    .ldio-0at4w42a1mmu div:nth-child(4) {
      transform: rotate(90deg);
      animation-delay: -0.6666666666666666s;
      background: var(--loader-color);
    }
    .ldio-0at4w42a1mmu div:nth-child(5) {
      transform: rotate(120deg);
      animation-delay: -0.5833333333333334s;
      background: var(--loader-color);
    }
    .ldio-0at4w42a1mmu div:nth-child(6) {
      transform: rotate(150deg);
      animation-delay: -0.5s;
      background: var(--loader-color);
    }
    .ldio-0at4w42a1mmu div:nth-child(7) {
      transform: rotate(180deg);
      animation-delay: -0.4166666666666667s;
      background: var(--loader-color);
    }
    .ldio-0at4w42a1mmu div:nth-child(8) {
      transform: rotate(210deg);
      animation-delay: -0.3333333333333333s;
      background: var(--loader-color);
    }
    .ldio-0at4w42a1mmu div:nth-child(9) {
      transform: rotate(240deg);
      animation-delay: -0.25s;
      background: var(--loader-color);
    }
    .ldio-0at4w42a1mmu div:nth-child(10) {
      transform: rotate(270deg);
      animation-delay: -0.16666666666666666s;
      background: var(--loader-color);
    }
    .ldio-0at4w42a1mmu div:nth-child(11) {
      transform: rotate(300deg);
      animation-delay: -0.08333333333333333s;
      background: var(--loader-color);
    }
    .ldio-0at4w42a1mmu div:nth-child(12) {
      transform: rotate(330deg);
      animation-delay: 0s;
      background: var(--loader-color);
    }
    .loadingio-spinner-spinner-i194m2purpg {
      width: 200px;
      height: 200px;
      display: inline-block;
      overflow: hidden;
      background: none;
    }
    .ldio-0at4w42a1mmu {
      width: 100%;
      height: 100%;
      position: relative;
      transform: translateZ(0) scale(1);
      backface-visibility: hidden;
      transform-origin: 0 0; /* see note above */
    }
    .ldio-0at4w42a1mmu div {
      box-sizing: content-box;
    }
  }

  &.destroy {
    opacity: 0;
  }
}
