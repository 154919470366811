@import "../../assets/styles/variables.scss";

.internal_dashboard {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 60px;

  .sidebar {
    position: fixed;
    width: 30%;

    a {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 119.22%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: rgb(0 0 0 / 62%);
      gap: 8px;
      margin-bottom: 24px;

      &:visited {
        color: rgb(0 0 0 / 62%);
      }

      svg {
        width: 24px;
        height: 24px;

        // path {
        //   fill: rgb(0 0 0 / 62%);
        //   opacity: 1;
        // }
      }

      &.active {
        color: #000000;
      }
    }
  }

  .body {
    padding-left: 30%;
    width: 100%;

    .heading {
      margin-bottom: 0;
    }

    .tabs {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 12px;
      margin: 20px 0;

      .tab {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 16px;
        gap: 8px;
        border: 1.5px solid rgba(152, 152, 152, 0.33);
        border-radius: 24px;

        .title {
          font-family: "Manrope";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 122%;
          color: #000000;
        }

        .counter {
          font-family: "Manrope";
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 122%;
          color: rgba(0, 0, 0, 0.3);
        }

        &.active {
          background-color: $blue;
          border-color: $blue;

          .title {
            color: #ffffff;
          }

          .counter {
            color: rgba(255, 255, 255, 0.3);
          }
        }
      }
    }

    .business_list {
      padding: 16px;
      background: #ffffff;
      border-radius: 12px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 26px;

      .business_item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;

        .left {
          max-width: 70%;

          .title {
            font-family: "Manrope";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: #000000;
          }
          .details {
            font-family: "Manrope";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            color: rgba(0, 0, 0, 0.5);
          }
        }
        .right {
          display: flex;
          align-items: center;
          gap: 8px;

          .btn.small.pill {
            padding: 4px 10px;
          }

          .edit_email {
            background-color: transparent;
          }
        }
      }
    }
  }

  .light_blue_btn {
    border-radius: 24px;
    background: rgba(12, 95, 253, 0.1);
    padding: 4px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    color: $blue;
    font-family: "manrope", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 122%;

    svg {
      height: 16px;
    }

    &.red {
      background-color: $light_red;
      color: $red;
    }
  }

  .update_business_btns {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }

  .prompt_editor_top_bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .gpt_variables {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 30px;
  }

  .preferences_switcher {
    display: block;
    margin: 20px 0;
    white-space: nowrap;
    width: 100%;
    overflow-y: auto;

    button {
      display: inline-block;
      margin-right: 10px;
      padding: 10px 16px;
      border-radius: 24px;
      color: #ffffff;
      font-family: "manrope", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 122%;
      border: 1.5px solid rgba(152, 152, 152, 0.33);
      color: #000000;

      span {
        color: #00000060;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 122%;
      }

      &.active {
        background: $blue;
        color: #ffffff;
        border-color: $blue;

        span {
          color: #ffffff60;
        }
      }
    }
  }

  .admin_preferences {
    width: 100%;
    margin: 10px 0;

    .preference {
      width: 100%;
      overflow: hidden;

      .preference_data {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 8px;
        margin: 6px 0;
        padding: 10px;
        border-radius: 12px;

        &:hover {
          background-color: rgba(12, 95, 253, 0.05);
        }

        .left {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 6px;

          .toggle {
            width: 24px;
            height: 24px;
            cursor: pointer;
            display: block;
          }

          .index {
            color: rgba(0, 0, 0, 0.5);
            font-family: "manrope", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

          .title {
            color: #000;
            font-family: "manrope", sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

            span {
              display: block;
            }

            .text {
              margin-bottom: 0;
            }
          }
        }

        .right {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 6px;

          button {
            background-color: transparent;
            cursor: pointer;
            border: none;

            svg {
              height: 100%;

              path {
                stroke: #000000;
              }
            }

            &.edit {
              width: 24px;
              height: 24px;
            }

            &.add {
              border-radius: 24px;
              padding: 4px 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 4px;
              color: $blue;
              font-family: "manrope", sans-serif;
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: 122%;
            }
          }

          .move {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px 6px;
            gap: 4px;
            border-radius: 60px;

            .down {
              transform: rotate(180deg);
            }
          }

          .delete {
            background-color: $light_red;
            border-radius: 24px;
            padding: 4px 10px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              transform: rotate(45deg);
              transform-origin: center;
              path {
                stroke: $red;
              }
            }
          }
        }
      }

      .sub_categories {
        width: 100%;
        padding-left: 46px;
        margin: 6px 0;
      }

      &.close {
        height: 60px;

        .preference_data .left .toggle {
          transform: rotate(180deg);
        }
      }
    }

    .custom_pref_box {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      gap: 10px;
      align-items: center;
      justify-content: space-between;

      .details {
        .pref {
          color: #000000;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        .cat {
          color: #00000060;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }

      .actions {
        display: flex;
        gap: 6px;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .internal_dashboard {
    padding: 30px 12px;

    .sidebar {
      width: 100%;
      margin-bottom: 50px;
      position: static;
    }

    .body {
      width: 100%;
      padding-left: 0;

      .tabs {
        .tab {
          width: 100%;
        }
      }

      .business_list {
        .business_item {
          .left {
            width: 100%;
          }
          .right {
            width: 100%;
            margin-top: 8px;
            justify-content: flex-end;
          }
        }
      }
    }
  }
}
