@import "../../../../assets/styles/variables.scss";

.preference_share_modal {
  .modal_popup {
    .popup_container {
      padding: 16px !important;
      display: block !important;
      max-width: 700px !important;
      border-radius: 24px;
    }
  }

  .share_form {
    margin-bottom: 24px;

    .form_body {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr;
      grid-template-rows: 1fr;
      grid-column-gap: 10px;
      grid-row-gap: 5px;

      label {
        margin-bottom: 0;
      }
    }
  }

  .copy_invite_link {
    margin-top: 6px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
    cursor: pointer;

    svg {
      width: 14px;
    }
  }

  .team_members_list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 24px;

    .team_member {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 12px;

      .left {
        display: flex;
        flex-direction: row;
        gap: 6px;
        align-items: center;
        justify-content: flex-start;

        .user_icon {
          width: 50px;
          height: 50px;
          border-radius: 100%;
          background-color: #f5e1cf;
          justify-content: center;
          text-align: center;
          display: flex;
          align-items: center;
          color: black;
          margin-right: 10px;
        }

        .sub_heading {
          margin-bottom: 0;
        }

        .text {
          text-transform: capitalize;
          margin-bottom: 0;
        }

        .info_text {
          text-transform: capitalize;
        }
      }
      .right {
        min-width: 150px;

        label {
          margin-bottom: 0;
        }
      }
    }
  }

  &.white {
    .heading,
    .sub_heading {
      color: #000000;
    }

    .text {
      color: $lightBlack;
    }

    .blue_text {
      color: $blue;
    }
  }
}

.b2b_account_modal {
  .stripe_box {
    border-radius: 12px;
    background: #0d0d0d;
    margin: 20px 0;
    padding: 20px;

    .box_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      border-bottom: 1px solid rgba(124, 125, 130, 0.3);
      padding-bottom: 16px;
      margin-bottom: 16px;
    }

    .perks {
      margin-top: 26px;

      ul {
        padding-left: 26px;
        list-style: none;

        li {
          position: relative;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.15px;

          &::before {
            content: "";
            width: 8px;
            height: 8px;
            position: absolute;
            left: -16px;
            top: 8px;
            background-color: $blue;
            border-radius: 50%;
          }
        }
      }
    }

    &.white {
      background-color: #ffffff;

      .perks {
        ul {
          li {
            color: #000000;
          }
        }
      }
    }
  }
}

.beta_modal_top_area {
  display: flex;
  width: 100%;
  margin-bottom: 24px;
  align-items: flex-start;

  justify-content: space-between;

  .close_thin {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;

    svg {
      width: 80%;
      height: 80%;

      path {
        stroke: #ffffff;
        opacity: 0.3;
      }
    }
  }

  &.white_bg {
    .close_thin {
      svg {
        path {
          stroke: #000000;
        }
      }
    }
  }
}

.preference_links {
  max-width: 700px;
  width: 100%;
  margin: 20px 0;

  .link {
    background-color: #000000;
    padding: 16px;
    border-radius: 12px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .actions {
      display: flex;
      gap: 18px;
      align-items: center;
      justify-content: center;
    }
  }
}

.pref_link_form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .form {
    width: 50%;

    .form_body {
      margin-top: 0;
    }
  }

  .preview {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px;

    .preview_cover {
      border-radius: 12px;
      overflow: hidden;
      display: block;
      width: 100%;
      height: auto;
      max-height: 120px;
      border-radius: 12px;
    }

    .copy_link {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
    }
  }
}

.unlinked_guests {
  .upcoming {
    color: #4585ff;
    font-family: "manrope";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 6px;

    &.past {
      color: rgba(255, 255, 255, 0.5);
      margin-top: 6px;
    }
  }
  .guest {
    border-radius: 12px;
    background: #0d0d0d;
    padding: 16px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;

    .sub_heading,
    .text {
      margin-bottom: 0;
    }

    .sub_heading {
      margin-bottom: 6px;
    }

    .input {
      width: auto;
      min-width: 200px;
      margin-bottom: 0;
    }
  }
}

.pref-link-comp-light {
  .heading,
  .sub_heading {
    color: #000000 !important;
  }

  .text {
    color: rgba(0, 0, 0, 0.6) !important;
  }

  .btn.dark_mode_grey_light {
    background: #e5e8ed !important;
    color: rgba(0, 0, 0, 0.6);
  }

  .preference_links {
    .link {
      background-color: #ffffff;

      .sub_heading {
        color: #000000;
      }

      .actions {
        button {
          color: #000000;
          svg path {
            stroke: #000000;
          }
        }
      }
    }
  }

  .input.image_input .btn.dark_mode_white {
    background-color: #e5e8ed;
    color: #000000;
  }

  .checkbox_input .label {
    color: #111014;
  }

  .slider {
    background-color: #d8e5ff;
  }
}

@media screen and (max-width: $mobile) {
  .preference_share_modal {
    .share_form {
      .form_body {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 10px;
        grid-row-gap: 5px;
      }
    }

    .team_members_list {
      .team_member {
        margin: 12px 0;

        .right {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .btn {
            width: 100%;
          }
        }
      }
    }
  }

  .b2b_account_modal {
    .stripe_box {
      .box_header {
        .left,
        .right {
          width: 100%;
        }

        .btn {
          display: block;
          width: 100%;
        }
      }
    }
  }

  .beta_modal_top_area {
    .close_thin {
      display: none;
    }
  }

  .pref_link_form {
    .form,
    .preview {
      width: 100%;
    }

    .preview {
      margin-bottom: 50px;
    }
  }

  .unlinked_guests {
    .guest {
      gap: 10px;

      .guest_details,
      .input {
        width: 100%;
      }
    }
  }
}
