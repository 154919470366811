@import "../../../assets/styles/variables.scss";

.offWhite_bg {
  background-color: #f6f6f6;
  min-height: 89vh;
}

.qr_code_button {
  margin: 20px auto;
  display: block;
  background-color: transparent;
  outline: none;
}

.invite_friends {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  background-color: transparent;

  svg {
    display: block;
    width: 16px;
  }

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 119.22%;
  }
}

.copy_preferences_link {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 119.22%;
  margin: 12px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
}

.flex_heading {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  text-align: center;
}

.add_new_business_share_btn {
  position: static !important;
  width: 100% !important;
  box-shadow: none !important;
}

.inner_container {
  .shared_business_timeline {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.shared_businesses {
  border-radius: 12px;

  .single_shared_business {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 14px 0;

    .single_shared_business_left_side {
      .business_name {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #ffffff;
      }

      .business_participants {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: #979dad;
      }
    }

    .single_shared_business_left_side {
      width: calc(100% - 40px);
    }

    .single_shared_business_right_side {
      width: 36px;
      background-color: transparent;

      svg {
        display: block;
        width: 100%;
        opacity: 0.3;
      }
    }
  }
}

.static_full_width {
  position: static !important;
  width: 100% !important;
  margin: 12px auto !important;
  box-shadow: none !important;
}

.static_auto_width {
  position: static !important;
  width: auto !important;
  margin: 12px auto !important;
  box-shadow: none !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.qr_share_header {
  .navigator_share_via_qr {
    background-color: transparent;
    width: 30px;
    position: absolute;
    top: 24px;
    left: 16px;

    svg {
      display: block;
      width: 100%;
    }
  }
}

.qr_container {
  position: relative;
  margin-bottom: 12px;

  .qr_share_div {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btlr_logo_qr {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #000000;
    height: 30px;
  }

  & + .text {
    max-width: 450px;
    margin: 0 auto;
  }
}

.search_location_header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 6px;
  position: relative;

  .reset_search {
    width: 32px;
    height: 32px;
    background-color: #1e1e1e;
    padding: 8px;
    position: absolute;
    right: 60px;
    top: 12px;

    svg {
      width: 100%;
      height: 100%;

      path {
        stroke: #ffffff;
      }
    }
  }

  &.dark {
    .input {
      background-color: #1e1e1e;

      input {
        color: #ffffff;

        &::placeholder {
          color: #1e1e1e;
        }
      }

      svg {
        path,
        circle {
          stroke: #ffffff;
        }
      }
    }

    .reset_search {
      background-color: #1e1e1e;

      svg path {
        stroke: #ffffff;
      }
    }
  }
}

.search_location_options {
  .single_option {
    padding: 8px 0;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 119.22%;
    color: #ffffff;
    cursor: pointer;
  }

  &.dark {
    .single_option {
      color: #ffffff;
    }
  }
}

.b2c_account_notice {
  font-size: 10px;
  color: rgba(0, 0, 0, 0.3);
}

.button-delete {
  color: rgba(242, 0, 44, 1);
  background-color: transparent;
  margin-bottom: 10px;
}
