@import "../../../assets/styles/variables.scss";

.signup {
  width: 100%;
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  background-color: $white;

  .close_btn {
    position: absolute;
    top: 2.5%;
    left: 2.5%;
    width: 25px;
    height: 25px;
    background-color: transparent;
    padding: 0;
    z-index: 10;

    svg {
      width: 100%;
      height: 100%;

      path {
        stroke: #ffffff;
      }
    }

    &.grey {
      left: unset;
      right: 24px;

      svg {
        path {
          stroke: #ffffff;
        }
      }
    }
  }

  .language_selector {
    margin-left: 0;
  }

  //   .form_inner_scroll {
  //     height: calc(100vh - 220px);
  //     width: 100%;
  //     overflow-y: auto;

  //     &::-webkit-scrollbar {
  //       width: 0px;
  //       border-radius: 2px;
  //     }

  //     // Bottoms styles if I ever want to style the scrollboar for mobile,
  //     // but for mobile effect, keeping it hidden
  //     &::-webkit-scrollbar-track {
  //       background: transparent;
  //     }

  //     &::-webkit-scrollbar-thumb {
  //       background: $lightBlack;
  //       border-radius: 2px;
  //     }

  //     &::-webkit-scrollbar-thumb:hover {
  //       background: #555;
  //     }
  //   }

  .welcome {
    background-color: #000000;
    width: 35%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 40px 20px;

    .btn {
      margin-top: 12px;
    }
  }

  .form_container {
    width: 35%;
    position: relative;
    padding: 40px 20px;
    overflow-y: auto;
    background-color: #000000;

    .back {
      display: block;
      width: 40px;
      height: 40px;
      background: transparent;
      outline: none;
      border: none;
      cursor: pointer;
      background: url(../../../assets/images/b2b/form/back.svg) no-repeat;
      background-size: 30%;
      background-position: left center;
      position: absolute;
      top: 0px;
      left: 0;
    }

    .form_inner {
      width: 100%;

      .form_header {
        width: 100%;
        position: relative;
        padding-top: 80px;
      }
    }

    .form_help_text {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 119.22%;
      color: $blue_2;
      text-align: center;
      margin: 18px 0;
      cursor: pointer;

      &.left_align {
        text-align: left;
      }
    }

    .form_info_text {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 120%;
      letter-spacing: 0.005em;
      color: $lightBlack;
      margin-bottom: 12px;
      margin-top: 12px;
    }

    .success {
      width: 100%;
      position: relative;
      display: block;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;

      .success_msg {
        text-align: left;
        width: 80%;
        margin: 0 auto;
        margin-top: -130px;
      }

      .user_email {
        width: 100%;
        padding: 18px 16px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 24px;
        margin-bottom: 24px;
        background: #eef1f4;
        border-radius: 12px;

        p {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 119.22%;
          color: $lightBlack;
        }
      }
    }
  }

  .remember_me {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;

    p.text {
      margin-right: 12px;
      margin-bottom: 2px;
      color: $lightBlack;
    }
  }

  .bg_container {
    position: fixed;
    right: 0;
    top: 0;
    width: 65%;
    height: 100vh;
    background: url(../../../../src/assets/images/b2c/auth/welcome.jpg)
      no-repeat;
    background-position: center;
    background-size: cover;
    backdrop-filter: brightness(0.5);

    .overlay {
      background: radial-gradient(rgb(162 158 158 / 20%) 100%, transparent);
      backdrop-filter: brightness(0.5);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .welcome_text {
      position: absolute;
      top: 35%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      width: 100%;

      .welcome_text_main {
        font-family: basierCircle-semibold;
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 119.22%;
        color: $grey_text;
      }
      .welcome_text_sub {
        font-family: basierCircle-semibold;
        font-style: normal;
        font-weight: 400;
        font-size: 50px;
        line-height: 119.22%;
      }

      &.business {
        top: unset;
        bottom: 10%;
      }
    }

    .logo {
      height: 32px;
      width: auto;
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &.business {
      background-image: url(../../../assets/images/b2b/auth/welcome.jpg);
      background-repeat: no-repeat;
    }
  }

  .business_signup_popup {
    padding: 30px;
    width: 100%;

    .input.date-input + .rdp_bg .rdp {
      left: 50%;
    }
  }

  .close_btn_popup {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 25px;
    height: 25px;
    background-color: transparent;
    padding: 0;
    z-index: 10;

    svg {
      display: block;
      width: 100%;
      height: 100%;

      path {
        stroke: #ffffff;
      }
    }
  }

  .white_path {
    svg {
      display: block;
      width: 100%;
      height: 100%;

      path {
        stroke: #ffffff;
      }
    }
  }
}

.form_privacy_notice {
  display: block;
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 12px */
  letter-spacing: 0.05px;
  margin-top: 12px;
  margin-bottom: 12px;
}

@media screen and (max-width: $mobile) {
  .signup {
    .close_btn {
      left: 5%;
      top: 5%;
    }

    .form_container {
      padding: 0;
    }

    .form_container,
    .welcome {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1;
      background: #000000;
      border-radius: 0;
      // padding: 20px;
      // text-align: center;

      &.popup {
        padding: 0;
      }
    }

    .welcome {
      background-color: #000000;
      position: absolute;
      bottom: 10%;
      left: 20px;
      top: unset;
      width: calc(100% - 40px);
      height: auto;
      min-height: auto;
      border-radius: 24px;
    }

    .form_container {
      .form_inner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 20px;

        .form_header {
          position: static;
        }

        &.move_top {
          top: 20%;
        }

        &.static {
          position: static;
          transform: none;
        }
      }

      .success_bg {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
      }
    }

    .bg_container {
      width: 100%;

      .logo {
        display: none;
      }

      .welcome_text {
        .welcome_text_main {
          font-size: 23px;
        }
        .welcome_text_sub {
          font-size: 33px;
        }

        &.business {
          bottom: 50%;
        }
      }
    }

    .business_signup_popup {
      padding: 0px;

      .heading {
        text-align: left !important;
      }

      .sub_heading {
        text-align: left !important;
      }

      .text {
        text-align: left !important;
      }
    }
  }
}
