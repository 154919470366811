@import "../../../assets/styles/variables.scss";

.form_body {
  margin-top: 24px;
  width: 100%;

  button[type="submit"] {
    font-family: "Manrope", sans-serif;
    padding: 17px 13px;
    background: $blue;
    border-radius: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: $white;
    cursor: pointer;
    transition: all 250ms ease-in-out;
    outline: none;
    border: none;
    user-select: none;
    width: 100%;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &.custom_submit {
      background-color: $blue;
      color: #fff;
      font-size: 16px;
      font-weight: normal;
      display: flex;
      align-items: center;
      border-radius: 30px;
      padding: 12px 40px;

      span {
        margin-left: 8px;
        width: 22px;

        svg {
          width: 100%;
        }
      }

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }

      &.custom_submit_2,
      &:hover {
        background-color: $blue;
      }
    }
  }

  .info_small_heading {
    margin-bottom: 20px;
  }
}
