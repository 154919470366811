// Breakpoints
$tablet: 900px;
$mobile: 700px;

// Colors
$blue: #3a82f7;
$blue_2: rgba(63, 93, 255, 1);
$blue_3: #4585ff;
$blue_2_bg: rgba(63, 93, 255, 0.12);
$dullBlue: #265b81;
$grey: #7c7d82;
$grey_text: #979dad;
$lightergrey: rgba(255, 255, 255, 0.3);
$lightgrey: rgba(255, 255, 255, 0.6);
$darkgrey: rgba(124, 125, 130, 0.3);
$white: rgba(255, 255, 255, 1);
$offWhite: #e5e8ed;
$black: #000000;
$lightBlack: rgba(0, 0, 0, 0.6);
$midBlack: rgba(0, 0, 0, 0.5);
$lighterBlack: rgba(0, 0, 0, 0.3);
$lightWhite: rgba(255, 255, 255, 0.1);
$red: rgba(229, 74, 74, 1);
$light_red: rgba(229, 74, 74, 0.1);
$dark_blue: #111521;
$darkModeGreyBG: rgba(174, 179, 193, 0.36);

// Form
$field_bg: rgba(238, 241, 244, 1);
$field_label: rgba(124, 125, 130, 1);

// Dashboard Colors
$dashboard_bg: rgb(246, 246, 246);
$dashboard_bg_light: rgba(246, 246, 246, 0.6);
$dashboard_red: #e64a4a;
$dashboard_grey: rgba(229, 232, 237, 0.3);
$dashboard_heading_grey: rgba(158, 157, 156, 1);

// Gradient backgrounds
$purpleBackground: radial-gradient(
      16.25% 55.73% at 8.86% 27.65%,
      rgba(12, 95, 253, 0.31) 0%,
      rgba(12, 95, 253, 0) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
  radial-gradient(
      18.63% 48.82% at 81.27% 38.47%,
      #0c5ffd 0%,
      rgba(12, 95, 253, 0) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
  linear-gradient(
    260.78deg,
    rgba(50, 110, 224, 0.22) -0.95%,
    rgba(41, 69, 123, 0.12) 69.23%
  );

$blackGradient: radial-gradient(
      71.04% 56.72% at 105.31% 108.99%,
      rgba(185, 236, 181, 0.2079) 0%,
      rgba(185, 236, 181, 0) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
  radial-gradient(
      88.04% 114.01% at -2.08% 0%,
      rgba(255, 169, 141, 0.116) 0%,
      rgba(236, 204, 181, 0) 77.6%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
  radial-gradient(
      65% 87.95% at 60.14% 39.73%,
      rgba(29, 35, 65, 0.6) 0%,
      rgba(17, 21, 33, 0) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
  #121318;

$creamGradient: radial-gradient(
      36.58% 119.47% at 94.64% 95.06%,
      rgba(255, 107, 0, 0.18) 0%,
      rgba(255, 141, 141, 0) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
  radial-gradient(
      79.17% 212.56% at 24.67% 30.71%,
      rgba(255, 230, 0, 0.1) 0%,
      rgba(236, 204, 181, 0) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
  #f6f6f6;

$pitchBlackGradient: radial-gradient(
      36.58% 119.47% at 94.64% 95.06%,
      rgba(124, 125, 130, 0.04) 0%,
      rgba(124, 125, 130, 0) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
  radial-gradient(
      79.17% 212.56% at 24.67% 30.71%,
      rgba(124, 125, 130, 0.14) 0%,
      rgba(124, 125, 130, 0) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
  #121318;

$pinKGradient: radial-gradient(
      52.11% 107.01% at 94.64% 95.06%,
      rgba(255, 169, 141, 0.162) 0%,
      rgba(236, 204, 181, 0.05) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
  radial-gradient(
      98.92% 158.64% at 22.23% 33.53%,
      rgba(18, 19, 24, 0.15) 0%,
      rgba(63, 93, 255, 0.128) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
  #121318;

$charcolGradient: radial-gradient(
      36.58% 119.47% at 94.64% 95.06%,
      rgba(63, 93, 255, 0.1) 0%,
      rgba(141, 153, 255, 0) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
  radial-gradient(
      79.17% 212.56% at 24.67% 30.71%,
      rgba(63, 93, 255, 0.05) 0%,
      rgba(221, 224, 255, 0) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
  #121318;

$purpleGradient: radial-gradient(
      52.11% 107.01% at 94.64% 95.06%,
      rgba(255, 169, 141, 0.162) 0%,
      rgba(236, 204, 181, 0.05) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
  radial-gradient(
      98.92% 158.64% at 22.23% 33.53%,
      rgba(18, 19, 24, 0.15) 0%,
      rgba(63, 93, 255, 0.128) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
  #121318;

$lightGreyGradient: radial-gradient(
      36.58% 119.47% at 94.64% 95.06%,
      rgba(124, 125, 130, 0.16) 0%,
      rgba(124, 125, 130, 0) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
  rgba(124, 125, 130, 0.15);

$darkGreyGradient: radial-gradient(
      47.34% 166.07% at 10.72% 98.02%,
      rgba(255, 169, 141, 0.0486) 0%,
      rgba(236, 204, 181, 0.015) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
  radial-gradient(
      98.92% 158.64% at 22.23% 33.53%,
      rgba(18, 19, 24, 0.15) 0%,
      rgba(63, 93, 255, 0.128) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
  rgba(124, 125, 130, 0.3);
