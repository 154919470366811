@import "../../../assets/styles/variables.scss";

.pills_input_Wrapper {
  .pills_input {
    margin-top: 12px;
    border-radius: 12px;
    background-color: #1e1e1e;
    border: transparent;
    position: relative;
    margin-bottom: 12px;
    cursor: pointer;
    min-height: 52px;

    * {
      transition: all 200ms ease-in-out;
    }

    .label {
      text-transform: capitalize;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 122%;
      color: #7c7d82;
      display: block;
      position: absolute;
      top: 15px;
      left: 16px;
      pointer-events: none;
    }

    .selected_pills {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 10px 16px;
      min-height: 52px;

      .selected_pill {
        border-radius: 18px;
        background: rgba(89, 113, 255, 0.1);
        color: #ffffff;
        font-size: 16px;
        font-weight: 500;
        width: fit-content;
        padding: 4px 12px;
        margin: 4px 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: capitalize;
        gap: 10px;
        margin-right: 8px;
        margin-top: 12px;

        svg {
          width: 12px;
          height: 12px;

          path {
            fill: #ffffff;
          }
        }

        &.pending {
          color: #ffffff;

          svg path {
            fill: #ffffff;
          }
        }

        &.approved {
          background: rgba(89, 113, 255, 0.1);
          color: #2571fd;

          svg path {
            fill: #2571fd;
          }
        }

        &.rejected {
          color: #e54a4a;

          svg path {
            fill: #e54a4a;
          }
        }
      }

      &.editable {
        outline: none;
        cursor: text;
        color: #000000;

        .selected_pill {
          position: relative;
        }

        .hidden_input {
          background-color: transparent;
          outline: none;
          border: none;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 119.22%;
          color: #ffffff;
          width: fit-content;

          &::placeholder {
            color: transparent;
          }
        }
      }
    }

    &.focused {
      .label {
        top: 10px;
        font-size: 14px;
        text-transform: uppercase;
        transform: translateY(0);
      }

      .selected_pills.editable {
        min-height: 64px;
        padding-top: 20px;

        .hidden_input {
          padding-top: 10px;
        }
      }
    }
  }

  .filter_list {
    border-radius: 12px;
    background: #1e1e1e;
    backdrop-filter: blur(22px);
    width: 100%;
    max-height: 200px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 10px;
      padding-right: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      height: 16px;
      border-radius: 4px;
      background-color: $grey_text;
    }

    .filter_item {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 119.22%;
      color: #ffffff;
      padding: 10px 20px;
      cursor: pointer;
      text-transform: capitalize;

      // &:hover {
      //   background-color: $lightgrey;
      // }

      &.add {
        color: $blue_2;
      }
    }
  }
}
