@import "../../../assets/styles/variables.scss";

:root {
  --PhoneInputCountryFlag-aspectRatio: 1;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px $field_bg inset !important;
}

.input {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 10px 16px;
  border-radius: 12px;
  position: relative;
  background-color: $field_bg;
  transition: all 250ms ease-in-out;

  &.transparent {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0 30px rgb(17, 21, 33) inset !important;
      -webkit-text-fill-color: #ffffff;
    }

    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.18);

    .label {
      color: $lightgrey;
    }

    input {
      color: #ffffff;

      &::placeholder {
        color: transparent;
      }

      &:focus,
      &:not(:placeholder-shown) {
        & + .label {
          // color: #ffffff;
        }
      }
    }

    &.select_input {
      select {
        color: $lightgrey;
        background-image: url("data:image/svg+xml;utf8,<svg width=%2720%27 height=%2713%27 viewBox=%270 0 20 13%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27><path opacity=%270.5%27 d=%27M2 2L10 10L18 2%27 stroke=%27white%27 stroke-width=%273%27/></svg>");

        option {
          color: #000000;
          background-color: $lightgrey;
        }

        &.selected {
          color: #ffffff;
        }
      }
    }

    &.phone_input_focused {
      .label {
        color: #ffffff;
      }
    }
  }

  &.grey {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0 30px #1e1e1e inset !important;
      -webkit-text-fill-color: #ffffff;
    }

    background-color: #1e1e1e;
    border: transparent;

    .label {
      color: #7c7d82;
    }

    input {
      color: #ffffff;

      &::placeholder {
        color: transparent;
      }

      &:focus,
      &:not(:placeholder-shown) {
        & + .label {
          // color: #ffffff;
        }
      }
    }

    &.select_input {
      select {
        color: #1e1e1e;
        background-image: url("data:image/svg+xml;utf8,<svg width=%2720%27 height=%2713%27 viewBox=%270 0 20 13%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27><path opacity=%270.5%27 d=%27M2 2L10 10L18 2%27 stroke=%27white%27 stroke-width=%273%27/></svg>");

        option {
          color: #000000;
          background-color: $lightgrey;
        }

        &.selected {
          color: #ffffff;
        }
      }
    }

    &.phone_input_focused {
      .label {
        color: #ffffff;
      }
    }
  }

  * {
    transition: all 250ms ease-in-out;
  }

  &.disable {
    pointer-events: none;
  }

  .input_border {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 1px solid $lighterBlack;
    border-radius: 12px;
    pointer-events: none;
    // transition: all 250ms ease-in-out;
  }

  .label {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 122%;
    color: $field_label;
    display: block;
    text-transform: capitalize;
    // transition: all 250ms ease-in-out;
    position: absolute;
    top: 15px;
    left: 16px;
    pointer-events: none;

    &.phoneType {
      left: 16px;
    }

    &.icon {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 6px;

      svg {
        width: 20px;
      }
    }
  }

  input,
  textarea {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 119.22%;
    color: $black;
    border: none;
    outline: none;
    display: block;
    width: 100%;
    background-color: transparent;
    height: 32px;
    // margin-top: 22px;

    &::placeholder {
      color: $field_bg;
    }

    &:focus,
    &:not(:placeholder-shown) {
      height: unset;
      margin-top: 22px;

      & + .label {
        // color: $lighterBlack;
        font-size: 14px;
        top: 10px;
        text-transform: uppercase;

        & + .input_border {
          border-color: $black;
        }

        &.icon {
          svg {
            width: 14px;
          }
        }
      }
    }
  }

  .label.move-label-up {
    font-size: 14px;
    top: 10px;
  }

  textarea {
    min-height: 100px;
  }

  .react-tel-input {
    font-family: "Manrope", sans-serif;
    font-size: inherit;
    position: static;

    .form-control {
      letter-spacing: unset;
      margin: 0 !important;
      padding: unset;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 119.22%;
      color: #ffffff;
      border: none;
      outline: none;
      display: block;
      width: 100%;
      background-color: transparent;
      height: 32px;
      padding-left: 0px;
    }

    .flag-dropdown {
      background-color: transparent;
      border: none;
      border-radius: 50%;
      display: none;

      &.open,
      &.open .selected-flag {
        background: transparent;
        border-radius: 50%;
      }
    }

    .selected-flag:hover,
    .selected-flag:focus {
      background-color: transparent;
    }

    .country-list {
      background: rgba(246, 246, 246, 1);
      border-radius: 12px;
      left: -14px;

      &::-webkit-scrollbar {
        width: 10px;
        padding-right: 4px;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        // width: 4px;
        height: 16px;
        border-radius: 4px;
        background-color: $grey_text;
      }

      .search {
        padding: 0;
        background: rgba(246, 246, 246, 0.7);
        backdrop-filter: blur(22px);
        width: 100%;
      }

      .search-box {
        margin-top: 0;
        margin-left: 0;
        margin-bottom: 2px;
        padding: 10px 16px;
        position: relative;
        background-color: #ffffff;
        height: auto;
        width: 100%;
        border-radius: 0;

        &:focus {
          margin: unset;
        }

        color: #000000;

        &::placeholder {
          color: $grey_text;
        }
      }

      .country {
        background-color: transparent;
      }

      .country-name {
        color: #000000;
      }
    }
  }

  &.phone_input_focused {
    .form-control {
      margin-top: 22px !important;
      height: unset;
      padding-left: 50px;
    }
    .flag-dropdown {
      display: block;
    }
    .label {
      color: $black;
      font-size: 14px;
      top: 10px;
      left: 64px;
    }
  }

  &.image_input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;

    .btn {
      width: calc(50% - 6px);
    }
  }

  &.select_input {
    position: relative;
    margin-bottom: 20px;
    padding: 0;

    select {
      padding: 16px 16px;
      border-radius: 12px;
      position: relative;
      background-color: $field_bg;
      -webkit-appearance: none;
      -moz-appearance: none;
      background-image: url("data:image/svg+xml;utf8,<svg width=%2720%27 height=%2713%27 viewBox=%270 0 20 13%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27><path opacity=%270.5%27 d=%27M2 2L10 10L18 2%27 stroke=%27black%27 stroke-width=%273%27/></svg>");
      background-repeat: no-repeat;
      background-position-x: 96%;
      background-position-y: 50%;
      font-family: "manrope", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 122%;
      color: $field_label;
      display: block;
      text-transform: capitalize;
      display: block;
      width: 100%;
      border: none;
      outline: none;
      background-color: transparent;
      cursor: pointer;

      option {
        margin-top: 11px;
        color: $field_label;
        font-family: "Manrope", sans-serif;
      }

      &.selected {
        color: #000000;
      }
    }
  }

  &.date-input {
    & + .rdp_bg {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 20px;
      z-index: 4;
      position: relative;

      .rdp {
        --rdp-outline: transparent;
        --rdp-outline-selected: transparent;
        --rdp-background-color: transparent;
        padding: 12px;
        background-color: #191919;
        border-radius: 12px;
        margin: 0;

        .rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
          background-color: transparent;
        }

        .rdp-dropdown_year,
        .rdp-dropdown_month {
          &:focus-visible {
            outline: none;
          }
        }
      }
    }
  }

  &.no-animation {
    input {
      height: 32px;
      margin-top: unset;
      padding-left: 28px;

      &:focus + .label {
        top: 15px;
        font-size: 18px;
        text-transform: capitalize;
      }
    }

    .label {
      // top: 15px !important;

      &.icon {
        svg {
          width: 20px !important;
        }
      }
    }
  }
}

.filtered_input {
  position: relative;
  z-index: 1;

  .filter_options {
    position: absolute;
    top: calc(100% + 6px);
    left: 0;
    width: 100%;
    max-height: 200px;
    border-radius: 12px;
    background: #0d0d0d;
    backdrop-filter: blur(22px);
    overflow-y: auto;
    padding: 20px;

    .filtered_option {
      color: #ffffff;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 119.219%;
      padding: 8px 0;
      cursor: pointer;
    }
  }

  &.static {
    .filter_options {
      position: relative;
      margin-top: 0px;
      margin-bottom: 12px;
    }
  }
}

.checkbox_input {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  input {
    position: absolute;
    width: 0;
    left: 50px;
    height: 0;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .checkmark {
      background: $blue_2;
      outline: 1px solid rgb(95, 126, 240);

      &::after {
        opacity: 1;
        transition: all 0.2s ease;
      }
    }
  }

  .checkmark {
    position: relative;
    display: block;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    background: white;
    border-radius: 50%;
    outline: none;
    transition: all 0.2s ease;

    &::after {
      position: absolute;
      display: block;
      content: "";
      left: 50%;
      top: 45%;
      width: 6px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: translate(-50%, -50%) rotate(45deg);
      -webkit-transform: translate(-50%, -50%) rotate(45deg);
      -moz-transform: translate(-50%, -50%) rotate(45deg);
      -ms-transform: translate(-50%, -50%) rotate(45deg);
      opacity: 0;
      transition: all 0.2s ease;
    }
  }

  .label {
    padding-left: 12px;
    font-size: 13px;
    width: calc(100% - 40px);
  }

  &.transparent {
    .label {
      color: $lightgrey;
    }
  }
}

.select_popup {
  // padding: 20px;

  .select_heading {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 119.22%;
    text-align: center;
    color: #000000;
  }

  .dropdown {
    background: #191919;
    border: 1px solid rgba(32, 33, 39, 0.04);
    border-radius: 12px;
    padding: 4px 16px;
    margin-top: 20px;
    margin-bottom: 190px;

    .dropdown_option {
      color: #ffffff;
      margin: 12px auto;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      border-bottom: 0.5px solid rgba(29, 30, 39, 0.12);
      padding-bottom: 18px;

      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }

      .option_details {
        display: flex;
        align-items: center;

        .label {
          margin-left: 8px;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 119.22%;
          letter-spacing: 0.03em;
          color: #ffffff;
          transition: all 250ms ease-in-out;
        }
      }

      .selected_checkmark {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        position: relative;
        background-color: rgba(58, 130, 247, 0.14);
        cursor: pointer;

        svg {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .close_popup_btn_bg {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 450px;
    background: #0d0d0d;
    height: 150px;
    z-index: 2;
  }

  .close_popup {
    display: block;
    width: 440px;
    margin: 16px 0;
    // box-shadow: 0px 15px 23px rgba(30, 52, 95, 0.5) !important;
    position: fixed;
    bottom: 60px;
    left: 5px;
    z-index: 3;
    background-color: rgba(174, 179, 193, 0.36);
    color: #ffffff;
  }
}

.input.select-padding {
  padding-top: 14px;
}

.otp-input {
  display: block;
  margin: 20px 0;

  div {
    display: flex;
    justify-content: space-between;
    gap: 6px;

    input {
      width: calc(100% / 6) !important;
      border-radius: 12px;
      background-color: #1b1b1b;
      color: rgba(255, 255, 255, 0.6);
      font-size: 16px;
      text-align: center;
      padding: 20px 0;
      outline: none;
      border: none;
    }
  }
}

.time-input {
  display: flex;
  flex-direction: row;
  gap: 8px;

  .input {
    width: 50%;
  }
}

.input_error {
  color: red;
  font-size: 12px;
  display: block;
  margin-bottom: 12px;
  margin-top: -10px;
}

@media screen and (max-width: 450px) {
  .input {
    .label {
      font-size: 16px;
    }
  }
  .select_popup {
    .close_popup {
      width: 95%;
      left: 2.5%;
    }
  }

  .location_search_fake_input {
    .modal_popup {
      .popup_container {
        top: 135%;
      }
    }
  }
}
