.b2c_account {
  .user-icon {
    border-radius: 100%;
    justify-content: center;
    text-align: center;
    display: flex;
    align-items: center;
    color: black;
    font-size: 30px;
    cursor: pointer;
    margin: 0 auto;
    margin-bottom: 8px;
    width: 60px;
    height: 60px;
    background-color: #dde0ff;
  }
}
