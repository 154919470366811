@import "../../../assets/styles/variables.scss";

.btn {
  display: block;
  cursor: pointer;
  outline: none;
  border: none;
  text-decoration: none;
  user-select: none;
  font-family: "Manrope", sans-serif;

  &.smallest {
    font-size: 12px;
    line-height: 14px;

    &.pill {
      padding: 4px 10px;
      border-radius: 18px;
    }
  }

  &.small {
    font-weight: 500;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.75px;

    &.pill {
      padding: 8px 20px;
      border-radius: 24px;
    }

    &.roundedSquare {
      padding: 8px 16px;
      border-radius: 8px;
    }

    &.hollow {
      padding: 8px 20px;
      border-radius: 24px;
    }
  }

  &.medium {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.75px;

    &.pill {
      padding: 15px 24px;
      border-radius: 30px;
    }

    &.roundedSquare {
      padding: 15px 24px;
      border-radius: 12px;
    }
  }

  &.blue {
    background-color: $blue;
    color: $white;
  }

  &.grey_blue {
    background-color: #262c3e;
    color: $white;
  }

  &.blue_bg_effect {
    background-color: rgba(12, 95, 253, 0.2);
    color: #3a82f7;
  }
  &.blue_bg_transparent {
    background-color: transparent;
    color: #4585ff;
    border: 1px solid #4585ff;
  }

  &.darkgrey {
    background-color: $darkgrey;
    color: $white;
  }

  &.blue_2 {
    background-color: $blue_2_bg;
    color: $blue_2;
  }

  &.blue_light {
    background: rgba(12, 95, 253, 0.1);
    color: $blue;
  }

  &.black_light {
    background-color: $lightBlack;
    color: #ffffff;
  }

  &.grey_light {
    background: #e5e8ed;
    color: #000000;
  }

  &.red {
    background-color: $light_red;
    color: $red;
  }

  &.dark_red {
    background-color: $red;
    color: $white;
  }

  &.white {
    background-color: #ffffff;
    color: #000000;
  }

  &.grey_2 {
    background-color: $grey_text;
    color: #ffffff;
  }

  &.black {
    background-color: #000000;
    color: #ffffff;
  }

  &.hollow_white {
    border: 1px solid $grey;
    color: $white;
    background-color: transparent;
  }

  &.hollow_blue {
    background-color: transparent;
    border: 1px solid #4585ff;
    color: #4585ff;
  }

  &.dark_mode_grey {
    background-color: $darkModeGreyBG;
    color: #ffffff;
  }

  &.dark_mode_white {
    background: rgba(255, 255, 255, 0.22);
    color: #ffffff;
  }

  &.dark_mode_grey_light {
    background: #1d2229;
    color: #7c7d82;
  }

  &.bg_transparent {
    background-color: transparent;
    color: #ffffff;
  }

  &.no_padding {
    padding: 0 !important;
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &.centered {
    margin: 0 auto;
  }

  &.flex {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }

  &.fullWidth {
    width: 100%;
    display: block;
  }

  &.red_pill {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #e54a4a;
      border: 2px solid #111521;
    }
  }
}
