@import "../../../assets/styles/variables.scss";

.modal_popup {
  display: block;
  width: 100%;
  height: 100vh;
  max-height: -webkit-fill-available;
  position: fixed;
  right: 0;
  top: 0;
  // background-color: rgb(255 255 255 / 5%);
  backdrop-filter: blur(6px);
  z-index: 2;
  transition: all 250ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;

  .close_btn_modal {
    position: absolute;
    right: 30px;
    top: 30px;
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;

    svg {
      path {
        stroke: white;
      }
    }
  }

  .popup_container {
    max-height: 70%;
    max-width: 600px;
    width: 90%;
    overflow-y: auto;
    background-color: #ffffff;
    // box-shadow: 25px 49px 82px rgba(63, 93, 255, 0.05);
    // backdrop-filter: blur(12px);
    border-radius: 24px;
    padding: 20px;
    transition: all 250ms ease-in-out;

    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      // width: 4px;
      // height: 16px;
      border-radius: 4px;
      background-color: transparent;
    }
  }

  &.close {
    visibility: hidden;
    opacity: 0;

    .popup_container {
      transform: translateY(-20px);
    }
  }

  &.open {
    visibility: visible;
    opacity: 1;

    .popup_container {
      transform: translateY(0px);
    }
  }

  &.bigger_size {
    .popup_container {
      // background-color: black;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 30px;
      width: 720px;
      max-width: 100%;
    }

    .w-50 {
      width: 100%;
      max-width: 500px;

      .form_body button[type="submit"] {
        display: block;
        width: auto;
        margin: 0 auto;
      }
    }

    .modal-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

@media screen and (max-width: $mobile) {
  .modal_popup {
    .popup_container {
      width: 90%;
      border-radius: 12px;
    }

    &.bigger_size {
      .w-50 {
        width: 100%;
      }
    }
  }
}
