@import "../../../assets/styles/variables.scss";

.b2c_signup {
  .bg_container {
    .logo {
      height: 70px;
    }

    .signup_link {
      position: absolute;
      top: 6%;
      right: 10px;
      transform: translate(0%, -100%);
    }

    .powered_by {
      position: absolute;
      right: 10px;
      bottom: 10px;
    }
  }

  .nav_go_back {
    background-color: transparent;
    padding: 10px;
    width: 50px;
    height: 50px;
    margin-top: 15px;

    svg {
      display: block;
      width: 100%;
      height: 100%;

      path {
        stroke: #ffffff;
      }
    }
  }
}

.form_container.popup {
  width: 100%;
  height: auto;
  position: relative;
  top: unset;
  left: unset;
  border-radius: 0;
}
