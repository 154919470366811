@import "../../../assets/styles/variables.scss";

#view-preference {
  max-width: 1500px;
  padding-left: 300px;
  margin-top: 90px;

  .container {
    // margin-top: 80px;
    // margin-bottom: 80px;
    min-height: calc(100vh - 230px);
    padding: 50px;
    width: 100%;
    background-color: black;

    .preferences_container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .left {
        width: 350px;
        padding-right: 10px;

        .guests_list {
          display: block;
          max-height: 50vh;
          overflow-y: auto;

          .guests_by_date {
            .text {
              margin-bottom: 6px;

              &.blue_text {
                color: $blue;
              }
            }

            .guests_div {
              margin: 6px 0;
              border-radius: 12px;
              background: #0d0d0d;

              .single_guest {
                width: 100%;
                background-color: transparent;
                border: none;
                outline: none;
                cursor: pointer;
                padding: 12px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                .left {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: flex-start;

                  .user-icon {
                    width: 50px;
                    height: 50px;
                    border-radius: 100%;
                    background-color: #dde0ff;
                    justify-content: center;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    color: black;
                    margin-right: 10px;
                    font-size: 30px;
                  }

                  .name {
                    color: #fff;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    text-align: left;

                    .info {
                      display: block;
                      margin-top: 4px;
                      color: rgba(255, 255, 255, 0.5);
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: normal;
                    }
                  }
                }

                .right {
                  display: block;
                  width: 56px;
                  height: 56px;

                  svg {
                    display: block;
                    width: 100%;
                    height: 100%;
                  }
                }
              }

              &.selected {
                border: 1px solid $blue_3;
              }
            }
          }
        }
      }

      .right {
        width: calc(100% - 350px);
        padding-left: 10px;
      }
    }
  }

  .beta_search_guest_input {
    display: block;
    width: 100%;

    .input {
      margin-bottom: 30px;

      .icon {
        svg {
          path {
            stroke: $lightgrey;
          }
          circle {
            stroke: $lightgrey;
          }
        }
      }
    }
  }
  .form-style {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 5px;
  }
  .preference-form {
    form {
      margin-top: 0;
    }

    .caption {
      color: rgba(12, 95, 253, 1);
      font-size: 20px;
    }
    label {
      // margin-bottom: 0;
    }
  }
  .custom_select_view_preferences {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    .input {
      width: fit-content;
      border: none;

      select {
        width: fit-content;
        padding: 0;
        // margin: 0 auto;
        opacity: 0.3;
        border-radius: 0;
        background-position-x: 0px;
        padding-left: 30px;
      }

      &.select-padding {
        padding: 0;
      }

      .label {
        display: none;
      }
    }

    .examples {
      .input {
        select {
          opacity: 0.3;
          color: #fff;
          // font-size: 12px;
          background-image: none;
          padding-left: 0;
        }
      }
    }
  }
  .ml-2 {
    margin-left: 1.2rem;
  }
  .-mb-1 {
    margin-bottom: -0.1rem;
  }
  .user-icon {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-color: #f5e1cf;
    justify-content: center;
    text-align: center;
    display: flex;
    align-items: center;
    color: black;
    margin-right: 10px;

    &.purple {
      background-color: #dde0ff;
    }
  }

  .white_box {
    background: #f6f6f6;
    border: 1px solid rgba(29, 30, 33, 0.82);
    border-radius: 16px;
    padding: 16px;
  }

  .preference_mobile_modal {
    display: none;
  }

  .non_modal_share_preferences {
    .modal_popup {
      &.close {
        position: static;
        visibility: visible;
        right: unset;
        top: unset;
        opacity: 1;
        display: block;
      }

      .close_btn_modal {
        display: none;
      }

      .popup_container {
        transform: none;

        .beta_modal_top_area {
          display: none;
        }
      }
    }
  }

  &.internal_dashboard {
    padding-top: 0;
    padding-bottom: 0;

    .top_bar {
      width: 100%;
    }

    .heading {
      color: #fff;
    }

    .sub_heading {
      color: #ffffff60;
    }

    .text.black_text {
      color: #ffffff60;
    }

    .preferences_switcher button {
      background-color: transparent;
      border: 1.5px solid rgba(152, 152, 152, 0.33);
      color: #fff;

      span {
        color: rgb(126 121 121 / 80%);
      }

      &.active {
        background-color: #2571fd;

        span {
          color: #fff;
        }
      }
    }

    .btn.grey_light {
      background-color: #1d2229;
      color: #ffffff60;
    }

    .white_box {
      background-color: #000000;

      .custom_pref_box {
        .details {
          .pref {
            color: #ffffff;
          }
          .cat {
            color: #7c7d82;
          }
        }
      }

      .preference .preference_data {
        .left {
          .toggle {
            svg path {
              stroke: #ffffff;
            }
          }

          .index {
            color: #ffffff60;
          }

          .title {
            color: #ffffff;

            .light_black_text {
              color: #ffffff60;
            }
          }
        }

        .right button svg path {
          stroke: #ffffff;
        }

        &:hover {
          background-color: rgb(255 255 255 / 5%);
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  #view-preference {
    padding-left: 0;
  }
}

@media screen and (max-width: $mobile) {
  #view-preference {
    margin-top: 30px;
    padding: 20px;

    .container {
      margin-top: 0px;
      padding: 20px;
      border-radius: 24px;

      .preferences_container {
        .left,
        .right {
          width: 100%;
        }

        .left {
          .guests_list {
            max-height: none;
          }
        }

        .right {
          display: none;
        }
      }
    }

    .preference_mobile_modal {
      display: block;
    }

    .beta_search_guest_input {
      justify-content: flex-start;

      .filtered_input {
        width: 100%;
      }

      .language_switch_footer {
        width: 150px;

        .selected_lang {
          padding: 10px 16px;
          border-radius: 8px;
        }

        .down_arrow {
          top: 10px;
        }
      }
    }

    .form-style {
      grid-template-columns: 1fr;
      grid-row-gap: 12px;
    }

    .w-50 {
      width: 100%;
    }

    &.internal_dashboard {
      padding: 20px;
    }
  }
}

// @media screen and (max-height: 780px) {
//   #view-preference {
//     .container {
//       min-height: 65vh;
//     }
//   }
// }
