@import "./variables.scss";
@import "./loaders.scss";

@font-face {
  font-family: basierCircle-medium;
  src: url(./fonts/BasierCircle-Medium.ttf);
}

@font-face {
  font-family: basierCircle-semibold;
  src: url(./fonts/BasierCircle-SemiBold.ttf);
}

@font-face {
  font-family: basierCircle-bold;
  src: url(./fonts/BasierCircle-Bold.ttf);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  overflow-x: hidden;
}

body {
  font-family: "Manrope", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(17, 21, 33, 1);
  color: $white;

  .hide_scrollbar {
    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: transparent;
    }

    &.horizontal_thumb {
      &::-webkit-scrollbar {
        width: 2px;
        height: 2px;
      }
      &::-webkit-scrollbar-thumb {
        height: 2px;
        border-radius: 4px;
        background-color: $lightergrey;
      }
    }
  }

  .cursor {
    cursor: pointer;
  }

  button {
    cursor: pointer;
    outline: none;
    border: none;
    user-select: none;
    background-color: transparent;
  }

  .container {
    width: 95%;
    max-width: 1100px;
    margin: 0 auto;
    position: relative;
    padding-top: 120px;

    section {
      position: relative;
    }

    // Helpers
    .align_right {
      display: flex;
      justify-content: flex-end;
    }

    .row-align-center {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .vertical_space {
      display: block;
      width: 100%;
      margin: 20px 0;

      &.line {
        height: 1px;
        width: 100%;
        background-color: #ffffff;
        max-width: 200px;
      }
    }

    .extra_margin {
      margin: 70px 0;
    }

    .extra_margin_sm {
      margin: 30px 0;
    }

    // Boxes
    .white_box {
      background: #ffffff;
      border-radius: 16px;
      padding: 16px;
    }

    .rounded_box {
      border: 3px solid rgba(255, 255, 255, 0.1);
      border-radius: 36px;
      margin: 16px 0;
      position: relative;

      &.lightgrey_border {
        border-color: $lightgrey;
      }

      &.lightblack_border {
        border-color: $lightBlack;
      }

      &.no_border {
        border: none;
      }

      .background_div {
        border-radius: 32px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: rotateY(180deg);
        // z-index: -1;
      }
    }

    .two_column_box {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      overflow: hidden;

      .column {
        width: 50%;
        position: relative;

        &.column_direction {
          flex-direction: column;
        }

        &.centered_content {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &.centered_content_left {
          display: flex;
          align-items: flex-start;
          justify-content: center;
        }

        &.centered_content_right {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }

        &.content_right_top {
          display: flex;
          align-items: flex-start;
          justify-content: flex-end;
        }
        &.content_right_bottom {
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
        }

        &.centered_content_bottom {
          display: flex;
          align-items: flex-end;
          justify-content: center;

          &.column_direction {
            align-items: center;
            justify-content: flex-end;
          }
        }

        &.right_content_bottom {
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
        }

        &.padded_inner {
          padding: 36px 36px;

          &.extra_top_bottom {
            padding-top: 80px;
            padding-bottom: 80px;
          }
        }

        &.non_padded_inner {
          padding: 0;
        }

        &.no_padding_top_bottom {
          padding-top: 0;
          padding-bottom: 0;
        }

        &.seventyWidth {
          width: 70%;
        }

        &.circle {
          width: 230px;
          height: 230px;
          border-radius: 9999px;
          overflow: hidden;
        }
      }

      &.sixtyFortySplit {
        justify-content: space-between;

        .column:first-child {
          width: 65%;
          padding-right: 16px;
        }
        .column:last-child {
          width: 35%;
        }

        &.precise {
          .column:first-child {
            width: 60%;
          }
          .column:last-child {
            width: 40%;
          }
        }
      }

      &.fortySixtySplit {
        justify-content: space-between;

        .column:first-child {
          width: 35%;
        }
        .column:last-child {
          width: 65%;
          padding-left: 16px;
        }
      }
      &.seventyThirtySplit {
        justify-content: space-between;

        .column:first-child {
          width: 70%;
        }
        .column:last-child {
          width: 30%;
          padding-left: 16px;
        }
      }

      &.centered {
        justify-content: center;
        align-items: center;
      }

      .column {
        & > img,
        .white_box > img,
        .black_box > img,
        .preferences_view img,
        .inner_coffee img,
        h1,
        h2 {
          max-width: 100%;
          max-height: 100%;
          transition: all 200ms ease-in-out;
        }
      }

      &:hover {
        .column {
          & > img,
          .white_box > img,
          .black_box > img,
          .preferences_view img,
          .inner_coffee img {
            transform: scale(1.05);
          }

          h1,
          h2 {
            transform-origin: 0;
          }
        }
      }

      &.eighty {
        gap: 20px;
        justify-content: space-between;

        .column {
          &:first-child {
            width: calc(80% - 25px);
          }
          &:last-child {
            width: 20%;
          }
          // width: unset;
        }
      }
    }

    .white_rounded_box {
      background-color: #ffffff;
      border-radius: 12px;
      padding: 18px;
      margin-bottom: 18px;
    }

    // Typography
    .heading {
      font-style: normal;
      font-weight: 700;
      font-size: 42px;
      margin-bottom: 12px;
      color: $white;
      line-height: 119.22%;

      &.semi_large {
        font-size: 40px;
      }

      &.medium {
        font-size: 32px;
      }

      &.ultra {
        font-size: 65px;
        line-height: 119%;
      }

      &.center_align {
        text-align: center;
      }

      &.white {
        color: #ffffff;
      }

      &.offWhite {
        color: $offWhite;
      }

      &.black {
        color: $black;
      }

      .blueRoundedBox {
        background-color: $blue;
        color: $white;
        padding: 4px 8px;
        border-radius: 12px;
      }

      &.gradient {
        background: radial-gradient(
            60.41% 116.87% at 43.13% 50%,
            rgba(255, 255, 255, 0) 0%,
            #b2caf6 100%
          ),
          #ffffff;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }

      .hashtag {
        font-size: 0.5em;
        color: $red;
      }
    }

    .sub_heading {
      font-family: "basierCircle-medium";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      letter-spacing: 0.15px;
      margin-bottom: 12px;
      line-height: 119.22%;

      &.fourHundred {
        font-weight: 400;
      }

      &.semi {
        font-size: 20px;
      }

      &.medium {
        font-size: 18px;
      }

      &.black {
        color: #000000;
      }

      &.center_align {
        text-align: center;
      }

      &.lightBlack {
        color: $lightBlack;
      }

      &.grey {
        color: $grey;
      }
    }

    .sub_heading_b {
      color: $grey;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 35px;
      letter-spacing: 0.15px;
    }

    .heading,
    .sub_heading,
    .sub_heading_b {
      &.limit {
        max-width: 870px;
        width: 95%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .quotation {
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      color: $offWhite;
      margin-bottom: 12px;
    }

    .text {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 122%;
      color: $offWhite;
      margin-bottom: 12px;

      &.grey {
        color: $grey;
      }

      &.grey_2 {
        color: $grey_text;
      }

      &.small {
        font-size: 12px;
      }

      &.smallest {
        font-size: 10px;
        line-height: 1;
      }
    }

    .pref_text {
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 122%;
      color: $offWhite;
      margin-bottom: 12px;
    }

    .pref_heading {
      font-family: "basierCircle-medium";
      color: #fff;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 119.219%;
    }

    .pref_subheading {
      color: #979dad;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 119.219%;
    }

    // B2B Typography
    .b_heading {
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 119.22%;
      color: $black;
    }

    .b_text {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 120%;
      color: $lightBlack;

      &.small {
        font-size: 12px;
      }

      &.centered {
        text-align: center;
      }
    }

    .inner_heading {
      font-family: "basierCircle-semibold";
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 119.22%;
      color: #000000;
      margin-bottom: 12px;

      .blue {
        color: $blue;
      }
    }

    .inner_heading_info {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      color: $dashboard_heading_grey;
    }

    .inner_small_info {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 122%;
      color: $dashboard_heading_grey;
    }

    .inner_smaller_info {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 119.22%;
      color: $dashboard_heading_grey;
      text-decoration: none;
    }

    .inner_semi_smaller_info {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 119.22%;
      color: $dashboard_heading_grey;
      text-decoration: none;
    }

    .inner_smallest_info {
      font-style: normal;
      font-size: 10px;
      line-height: 119.22%;
      color: $dashboard_heading_grey;
      text-decoration: none;
    }

    .manrope {
      font-family: "manrope", sans-serif;
    }

    .basier {
      font-family: "basierCircle-semibold", sans-serif;
    }

    .basier_medium {
      font-family: "basierCircle-medium", sans-serif;
      font-weight: 500;
    }

    .white_text {
      color: #ffffff;
    }

    .offWhite_text {
      color: #e5e8ed;
    }

    .blue_text {
      color: $blue;
    }

    .blue_3_text {
      color: $blue_3;
    }

    .dull_blue_text {
      color: $dullBlue;
    }

    .red_text {
      color: $dashboard_red;
    }

    .black_text {
      color: #000000;
    }

    .mid_black_text {
      color: $midBlack;
    }
    .light_black_text {
      color: $lightBlack;
    }
    .lighter_black_text {
      color: $lighterBlack;
    }

    .lighter_grey_text {
      color: $lightergrey;
    }

    .light_grey_text {
      color: $lightgrey;
    }
    .dark_grey_text {
      color: $darkgrey;
    }

    .font_700 {
      font-weight: 700;
    }

    .center_align {
      text-align: center;
    }

    .left_align {
      width: 100%;
      text-align: left;
    }

    a {
      text-decoration: none;
      color: $blue;
    }

    // Design elements
    .bg_blur {
      width: 988.83px;
      height: 717.22px;
      background: radial-gradient(
        50% 50% at 50% 50%,
        #3f5dff 0%,
        rgba(63, 93, 255, 0) 100%
      );
      opacity: 0.2;
      pointer-events: none;
    }

    .mirrored {
      transform: rotateY(180deg);
    }
  }

  &.b2b {
    background-color: $white;

    .container {
      width: 100%;
      max-width: 100%;
      padding-top: 0;
    }

    &.beta {
      background-color: #000000;

      .container {
        width: 100%;
        max-width: 100%;
        padding-top: 0;
      }
    }
  }

  // Invite button
  &.invite_left {
    .invite_to_btlr {
      right: unset;
      left: 12px;
    }
  }
}

// Toastify
:root {
  --toastify-toast-background: rgba(29, 30, 33, 0.82);
  --toastify-icon-color-success: #3f5dff;
  --toastify-color-progress-dark: #3f5dff;
  --toastify-color-progress-success: #3f5dff;
}

.Toastify__toast {
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.16);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
  backdrop-filter: blur(3px);
}

@media screen and (max-width: $tablet) {
  body {
    .container {
      .two_column_box {
        &:hover {
          .column {
            & > img,
            .white_box > img,
            .black_box > img,
            .preferences_view img,
            .inner_coffee img {
              transform: none;
            }
          }
        }

        &.eighty {
          .column:first-child,
          .column:last-child {
            width: 100%;
          }
        }
      }

      .heading {
        font-size: 30px;

        &.medium {
          font-size: 24px;
        }

        &.ultra {
          font-size: 48px;
        }
      }

      .sub_heading {
        &.medium {
          font-size: 16px;
        }
      }

      .pref_text {
        font-size: 24px;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  body {
    section {
      padding: 0 10px;
    }

    .container {
      .two_column_box {
        .column,
        .column:first-child {
          width: 100%;

          &.padded_inner {
            &.extra_top_bottom {
              padding-top: 20px;
              padding-bottom: 20px;
            }
          }
        }

        &.sixtyFortySplit .column,
        &.fortySixtySplit .column,
        &.seventyThirtySplit .column,
        &.precise .column {
          width: 100% !important;

          &.zero_padding_mobile {
            padding: 0 !important;
            margin: 12px 0;
          }

          &:last-child {
            padding-left: 0;
          }
        }
      }

      .heading {
        font-size: 28px;

        &.semi_large {
          font-size: 26px;
        }

        &.medium {
          font-size: 20px;
        }

        &.ultra {
          font-size: 40px;
        }

        .blueRoundedBox {
          margin-top: 4px;
          margin-bottom: 4px;
          display: inline-block;
        }
      }

      .sub_heading {
        font-size: 16px;

        &.medium {
          font-size: 14px;
        }
      }

      .pref_text {
        font-size: 18px;
      }

      .pref_heading {
        font-size: 22px;
      }

      .pref_subheading {
        font-size: 12px;
      }

      .inner_heading {
        font-size: 22px;
      }
    }
  }
}

// Header
.nav_go_back {
  background-color: transparent;
  padding: 10px;
  position: absolute;
  left: -30px;
  top: -15px;
  z-index: 10;

  svg path {
    stroke: #ffffff;
  }

  &.with_logo {
    top: 18px;
    left: -18px;
  }

  &.logged_in {
    position: static;
    display: block;
    margin-bottom: 12px;
    padding: 0;
  }
}

.b2c_sidebar_nav {
  position: relative;

  .user-icon {
    width: 34px;
    height: 34px;
    border-radius: 100%;
    background-color: #f5e1cf;
    justify-content: center;
    text-align: center;
    display: flex;
    align-items: center;
    color: black;
    margin-right: 10px;
    cursor: pointer;
    font-size: 24px;

    &.purple {
      width: 50px;
      height: 50px;
      background-color: #dde0ff;
      margin-bottom: 40px;
      font-size: 30px;
    }
  }

  .nav_link {
    color: #8f8f91;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 121.5%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
    margin: 20px 0;

    .nav_icon {
      svg path:not(.stroke) {
        fill: #8f8f91;
      }

      svg path.stroke {
        stroke: #8f8f91;
      }

      &.notification {
        position: relative;

        &::before {
          content: "";
          width: 6px;
          height: 6px;
          background-color: #e54a4a;
          border: 1px solid #000000;
          top: 0;
          left: 0;
          position: absolute;
          border-radius: 50%;
        }
      }
    }

    &.active {
      color: #ffffff;

      .nav_icon {
        svg path:not(.stroke) {
          fill: #ffffff;
        }

        svg path.stroke {
          stroke: #ffffff;
        }

        svg circle {
          stroke: #ffffff;
        }
      }
    }
  }

  .desktop {
    display: block;
  }

  .mobile {
    display: none;
  }
}

.b2c_nav_top_btns {
  display: flex;
  flex-direction: row;
  gap: 10px;

  .btn.medium.roundedSquare {
    width: 50%;
    padding: 15px 18px;
  }
}

.nav_box {
  background: #191919;
  border-radius: 12px;
  margin: 12px 0;
  padding: 0px 16px 0px 16px;
  cursor: pointer;

  .nav_link_pref {
    border-bottom: 1px solid rgba(29, 30, 39, 0.12);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 14px;

    .nav_left_side {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      .nav_icon {
        width: 24px;

        svg {
          display: block;
          width: 100%;

          path:not(.stroke) {
            fill: #ffffff;
          }

          path.stroke {
            stroke: #ffffff;
          }

          path.logout {
            stroke: #e54a4a;
          }
        }
      }

      .user-icon {
        width: 60px;
        height: 60px;
        border-radius: 100%;
        background-color: #f5e1cf;
        justify-content: center;
        text-align: center;
        display: flex;
        align-items: center;
        color: black;
        margin-right: 10px;
        cursor: pointer;
        font-size: 38px;

        &.notification {
          position: relative;

          &::after {
            content: "";
            width: 12px;
            height: 12px;
            background-color: #e54a4a;
            border: 2px solid #000000;
            top: 0;
            right: 0;
            position: absolute;
            border-radius: 50%;
          }
        }
      }

      p {
        color: #fff;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 119.219%; /* 21.459px */

        span {
          display: block;
          color: rgba(255, 255, 255, 0.6);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 119.219%; /* 16.691px */
        }
      }
    }

    .nav_right_side {
      width: 24px;

      svg {
        display: block;
        width: 100%;

        path {
          stroke: #ffffff;
          opacity: 0.3;
        }
      }

      &.full_opacity {
        svg path {
          opacity: 1;
        }
      }
    }

    &:last-child {
      border: none;
    }
  }

  &.dark {
    background: radial-gradient(
        65.24% 71.34% at 86.53% 38.73%,
        rgba(185, 209, 255, 0) 0%,
        rgba(185, 226, 255, 0) 100%
      ),
      #161616;

    .nav_link_pref {
      text-decoration: none;

      .nav_left_side {
        p {
          color: #ffffff;
        }
      }

      .nav_right_side {
        svg {
          opacity: 1;
        }
      }
    }
  }
}

@media screen and (max-width: $tablet) {
  .header {
    &.sticky {
      backdrop-filter: blur(5px);
    }

    .mobile_open {
      display: block;
      position: absolute;
      top: 50%;
      right: 5%;
      transform: translate(-50%, -50%);
      background: transparent;
      outline: none;
      border: none;
    }

    .inner {
      margin: 0;
      padding: 20px 10px;

      .left {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 5%;

        .logo {
          margin: 0;
          height: 20px;
        }
      }

      .left .navLinks,
      .right {
        display: none;
      }

      .mobile_header_bottom {
        margin-top: 30px;

        .socials_mobile {
          margin-bottom: 24px;
          display: flex;
          flex-direction: row;
          gap: 12px;

          .social_pill {
            padding: 10px;
            border-radius: 24px;
            background-color: $darkgrey;

            .icon {
              display: block;
              width: 16px;
            }
          }
        }

        .login_buttons_mobile {
          display: flex;
          flex-wrap: wrap;
          gap: 12px;
        }
      }
    }

    .mobile_nav {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.6);
      backdrop-filter: blur(10px);
      z-index: 999999999999999999;
      opacity: 0;
      pointer-events: none;
      visibility: visible;

      .inner {
        display: block;
        position: relative;
        width: 80%;
        height: 100vh;
        padding: 24px;
        background: rgba(17, 21, 33, 1);
        border-radius: 0px 24px 24px 0px;
        transform: translateX(-100%);
        transition: all 300ms ease-in-out;

        .logo_mobile {
          display: block;
          height: 50px;
          margin-top: 15px;
        }

        .close {
          width: 20px;
          height: 20px;
          position: absolute;
          top: 52px;
          right: 20px;
          background-color: transparent;
          outline: none;
          border: none;

          svg {
            width: 100%;
          }
        }

        .mobile_links {
          display: block;
          width: 100%;
          margin-top: 44px;

          .mobile_link {
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            line-height: 30px;
            color: $offWhite;
            margin-bottom: 24px;
            display: block;
            position: relative;
            text-decoration: none;
            transition: all 300ms ease-in-out;

            &::after {
              content: "";
              background-color: $blue;
              width: 34px;
              height: 2px;
              position: absolute;
              left: 0;
              bottom: -4px;
              width: 0;
              transition: all 300ms ease-in-out;
            }

            &:hover,
            &.active {
              color: $white;

              &::after {
                width: 34px;
              }
            }
          }
        }
      }

      &.open {
        animation-name: mobileNav;
        animation-duration: 300ms;
        animation-fill-mode: forwards;

        .inner {
          transform: translateX(0);
        }
      }

      &.close {
        animation-name: mobileNavClose;
        animation-duration: 300ms;
        animation-fill-mode: forwards;

        .inner {
          transform: translateX(-100%);
        }
      }
    }

    @keyframes mobileNav {
      0% {
        visibility: hidden;
        opacity: 0;
      }
      10% {
        visibility: visible;
      }
      100% {
        opacity: 1;
        pointer-events: unset;
      }
    }

    @keyframes mobileNavClose {
      0% {
        opacity: 1;
        pointer-events: unset;
      }
      100% {
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .b2c_sidebar_nav {
    .desktop {
      display: none;
    }

    .mobile {
      display: block;

      .bg {
        position: fixed;
        left: 0;
        top: 0;
        background-color: #000000;
        height: 68px;
        z-index: 1;
        width: 100%;
      }

      .user-icon {
        position: fixed;
        left: 20px;
        top: 20px;
        z-index: 2;
      }

      .b2b_mobile_nav_open {
        position: fixed;
        padding: 6px;
        right: 20px;
        top: 20px;
        z-index: 1;
        background-color: transparent;
        border: none;
        outline: none;
      }

      .mobile_bottom_nav {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 1;
        background: rgba(54, 54, 55, 0.8);
        backdrop-filter: blur(10px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;

        .nav_link {
          color: #8f8f91;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 121.5%; /* 14.58px */
          flex-direction: column;

          .nav_icon {
            display: block;
            margin: 0 auto;
          }

          &.active {
            color: #3a82f7;

            .nav_icon {
              svg {
                path:not(.stroke) {
                  fill: #3a82f7;
                }

                path.stroke {
                  stroke: #3a82f7;
                }
              }
            }
          }
        }
      }
    }
  }
}
