.confirm_b2c_account {
  position: fixed;
  top: 0;
  left: 0;
  width: 100.1vw;
  height: 100.1vh;
  background-image: url(../../../assets/images/b2c/beta/confirmed_bg.jpg);
  background-position: center;
  background-size: cover;
  padding: 20px;

  &.b2b {
    background-image: url(../../../assets/images/b2b/auth/confirm.jpg);
  }

  .close_btn {
    background-color: transparent;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 999;

    svg {
      display: block;
      width: 100%;
      height: 100%;

      path {
        stroke: #ffffff;
      }
    }
  }

  .confirm_b2c_title {
    font-family: "basier", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    text-align: center;
    background: radial-gradient(
          60.41% 116.87% at 43.13% 50%,
          rgba(255, 255, 255, 0) 0%,
          #b2caf6 100%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      #ffffff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-top: 80px;
    margin-bottom: 12px;
  }

  .email_confirmed {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    img {
      width: 100%;
      max-width: 450px;
      display: block;
      margin: 0 auto;
    }
  }

  .bottom_btn {
    position: absolute;
    bottom: 12vh;
    left: 20px;
    width: calc(100% - 40px);

    .static_full_width {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      max-width: 500px;

      .share_icon_white {
        display: flex;
        align-items: center;
        justify-content: center;

        path {
          fill: #000000;
        }
      }
    }
  }
}

.gmail_link_container {
  display: flex;
  align-items: center;
  justify-content: center;

  .gmail_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }
}
