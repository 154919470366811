@import "../../../assets/styles/variables.scss";

.switch {
  display: inline-block;
  width: 40px;
  height: 24px;
  position: relative;
  user-select: none;
  outline: none;
  //   right: 10px;

  & + span {
    margin-left: 15px;
    height: 100%;
  }
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;

  &:focus {
    outline: none;
    border: none;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #001540;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  user-select: none;
  outline: none;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 2px;
  bottom: 1px;
  background-color: #7c7d82;
  transition: 0.4s;
}

input:checked + .slider {
  //   background-color: $blue_2;

  &::before {
    background-color: #3a82f7;
  }
}

input:focus + .slider {
  box-shadow: 0 0 1px #3a82f7;
}

input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 22px;
}

.slider.round:before {
  border-radius: 50%;
}
