@import "../../../assets/styles/variables.scss";

.slide_in_popup {
  display: block;
  width: 100%;
  height: 100vh;
  max-height: -webkit-fill-available;
  position: fixed;
  right: 0;
  top: 0;
  background-color: $lightWhite;
  backdrop-filter: blur(6px);
  z-index: 2;
  transition: all 250ms ease-in-out;
  z-index: 20;

  .popup_container {
    position: absolute;
    top: 0;
    right: 0;
    width: 25%;
    height: 100%;
    background-color: #ffffff;
    box-shadow: -1px -1px 16px rgb(0 0 0 / 16%);
    transition: all 250ms ease-in-out;

    .close_btn {
      background-color: transparent;
      width: 28px;
      height: 28px;
      position: absolute;
      top: 28px;
      left: 5%;
    }

    .popup_body {
      height: calc(100% - 120px);
      overflow-y: auto;
      width: 90%;
      margin: 0 auto;
      margin-top: 80px;
    }

    &.blue_bg {
      background-color: $dark_blue;

      .close_btn {
        path {
          stroke: #ffffff;
        }
      }
    }

    &.auto_height {
      height: auto !important;
    }
  }

  &.left {
    left: 0;
    right: unset;

    .popup_container {
      left: 0;
      right: unset;
      width: 35%;

      .close_btn {
        left: unset;
        right: 5%;
      }
    }

    &.close {
      visibility: hidden;
      opacity: 0;

      .popup_container {
        transform: translateX(-100%);
      }
    }
  }

  &.bottom {
    right: unset;
    left: 0;
    bottom: 0;
    top: unset;

    .popup_container {
      top: unset;
      right: unset;
      left: 0;
      bottom: 0;
      width: 100%;
      box-shadow: 0px -4px 39px -14px rgba(35, 66, 123, 0.46);
      border-radius: 24px 24px 0px 0px;
      transform: translateY(0%);
      height: 90%;
      padding: 12px;

      .popup_body {
        // height: 100%;
        margin: 0;
        width: 100%;

        &::-webkit-scrollbar {
          width: 10px;
          padding-right: 4px;
        }

        &::-webkit-scrollbar-track {
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          // width: 4px;
          height: 16px;
          border-radius: 4px;
          background-color: $grey_text;
        }
      }

      .bar_container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 16px 0;
        position: relative;
        margin-top: 0;
        height: 30px;
      }
      .bar {
        width: 66px;
        height: 2.5px;
        background-color: rgba(0, 0, 0, 0.15);
        position: absolute;
        top: 25%;
        left: 50%;
        transform: translate(-50%, -50%);

        &.title {
          background-color: transparent;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 119.219%;
          color: #000000;
          text-transform: capitalize;
          height: unset;
          width: unset;
          top: 50%;
        }
      }

      .close_btn {
        right: 0;
        left: unset;
        top: 0;
        width: 28px;
        height: 28px;
        padding: 6px;

        svg {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      .back_btn {
        position: absolute;
        left: 0;
        top: 0;
        width: 28px;
        height: 28px;
        background-color: transparent;
      }
    }

    &.close {
      visibility: hidden;
      opacity: 0;

      .popup_container {
        transform: translateY(100%);
      }
    }
  }

  &.close {
    visibility: hidden;
    opacity: 0;

    .popup_container {
      transform: translateX(100%);
    }
  }

  &.open {
    visibility: visible;
    opacity: 1;

    .popup_container {
      transform: translateX(0);
    }
  }

  &.dark {
    .popup_container {
      .back_btn {
        svg path {
          stroke: #ffffff;
        }
      }
      .bar {
        background-color: rgba(255, 255, 255, 0.15);

        &.title {
          color: #ffffff;
        }
      }

      .close_btn {
        svg path {
          stroke: #ffffff;
        }
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .slide_in_popup {
    .popup_container {
      width: 85%;
    }

    &.left {
      .popup_container {
        width: 100%;
      }
    }

    &.bottom {
      .popup_container {
        // height: 75%;

        .popup_body {
          &::-webkit-scrollbar {
            width: 0px;
            display: none;
          }

          &::-webkit-scrollbar-track {
            background-color: transparent;
          }

          &::-webkit-scrollbar-thumb {
            display: none;
          }
        }
      }
    }
  }
}
