@import "../../assets/styles/variables.scss";

.payment_successful {
  display: flex;
  min-height: 100vh;
  flex-wrap: wrap;
  flex-direction: row;
  position: relative;

  .close {
    position: absolute;
    top: 2.5%;
    left: 2.5%;
    width: 25px;
    height: 25px;
    background-color: transparent;
    padding: 0;

    svg {
      width: 100%;
      height: 100%;

      path {
        stroke: #000000;
      }
    }
  }

  .side {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 5%;

    &.receipt {
      background-color: #ffffff;
      align-items: flex-start;

      .logo {
        width: 30%;
      }

      .payment_details {
        width: 100%;
        margin-top: 30px;

        .payment_breakdown {
          width: 100%;
          margin-top: 30px;

          .detail {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .text {
              margin-bottom: 0;
            }
          }

          .divider {
            display: block;
            width: 100%;
            height: 1px;
            background-color: $lighterBlack;
            margin: 4px 0;
          }
        }
      }
    }

    &.confirmation_message {
      background-color: #f6f6f6;

      .success_image {
        width: 100%;
      }

      .message_details {
        margin-top: -80px;
        text-align: center;

        .b_heading {
          margin-bottom: 12px;
        }

        .user_email {
          width: 100%;
          padding: 18px 16px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-top: 44px;
          margin-bottom: 24px;
          background: #eef1f4;
          border-radius: 12px;

          p {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 119.22%;
            color: $lightBlack;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .payment_successful {
    flex-direction: column-reverse;
    .side {
      width: 100%;
      min-height: 50vh;

      &.confirmation_message {
        padding-top: 60px;
      }
    }
  }
}
